<template>
  <div>
    <NavigationBar titel="Zielvorgaben" isPreview></NavigationBar>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";

export default {
  name: "member-management-performance-targets",
  components: {
    NavigationBar,
  },
  data() {
    return {};
  },
};
</script>
